export default [
  {
    title: 'Dashboard',
    route: 'dashboard-ecommerce',
    icon: 'HomeIcon',
    role: ['fa-coal-manager','president-director','direksi','super-admin','admin','administrator', 'manager-area', 'admin','administrator', 'president','vice-president', 'direksi', 'finance','laboratory-manager','supervisor-cabang','warehouse-cabang','purchasing-cabang', 'user', 'stock-manager'],
  },
  {
    title: 'User',
    route: 'apps-users-list',
    icon: 'UserIcon',
    role: ['super-admin','admin','administrator'],
  },
  {
    title: 'Branches',
    route: 'apps-procurement-branch-list',
    icon: 'ArchiveIcon',
    role: ['super-admin'],
  },
  {
    title: 'Regions',
    route: 'apps-procurement-region-list',
    icon: 'BookOpenIcon',
    role: ['super-admin'],
  },
  {
    title: 'Departements',
    route: 'apps-procurement-departements-list',
    icon: 'SettingsIcon',
    role: ['super-admin', 'admin','administrator'],
  },
  {
    title: 'Roles',
    route: 'apps-roles-list',
    icon: 'ShieldIcon',
    role: ['super-admin'],
  },
  {
    title: 'Units',
    route: 'apps-unit-list',
    icon: 'ArchiveIcon',
    role: ['super-admin','admin','administrator'],
  },
  {
    title: 'Stock Histories',
    route: 'apps-stocks-list',
    icon: 'TruckIcon',
    role: ['super-admin','stock-manager','admin','administrator', 'warehouse-cabang'],
  },
  {
    title: 'Items',
    route: 'apps-items-list',
    icon: 'FileTextIcon',
    role: ['super-admin','warehouse-cabang','admin','administrator', 'laboratory-manager'],
  },
  {
    title: 'Assets',
    route: 'apps-procurement-assets-list',
    icon: 'HardDriveIcon',
    role: ['super-admin','warehouse-cabang','admin','administrator', 'laboratory-manager', 'finance', 'division-head', 'fa-coal-manager', 'regional-manager'],
  },
  {
    title: 'Receive Assets',
    route: 'apps-assets-receive-list',
    icon: 'ArrowDownCircleIcon',
    role: ['super-admin','admin','administrator', 'laboratory-manager', 'finance'],
  },
  {
    title: 'Item Stocks',
    route: 'apps-item-stocks-list',
    icon: 'BoxIcon',
    role: ['super-admin','manager-lab-cabang','stock-manager','warehouse-cabang','admin','administrator', 'laboratory-manager'],
  },
  {
    title: 'Supplier',
    route: 'apps-supplier-list',
    icon: 'TruckIcon',
    role: ['super-admin','admin','administrator'],
  },
  {
    title: 'Material Requests',
    route: 'apps-material-request-list',
    icon: 'ArrowDownCircleIcon',
    role: ['super-admin','supervisor-cabang','warehouse-cabang','user', 'laboratory-manager', 'purchasing-cabang', 'administrator'],
  },
  {
    title: 'Receive MR',
    route: 'apps-material-request-receive-list',
    icon: 'ArrowDownCircleIcon',
    role: ['super-admin','user'],
  },
  {
    title: 'PR Plan',
    route: 'apps-procurement-pr-plan-list',
    icon: 'ClipboardIcon',
    role: ['super-admin','purchasing-cabang','warehouse-cabang', 'laboratory-manager'],
  },
  {
    title: 'Purchase Request',
    route: 'apps-procurement-purchase-request-list',
    icon: 'ArrowDownCircleIcon',
    role: ['super-admin','purchasing-cabang', 'laboratory-manager','warehouse-cabang', 'regional-manager', 'division-head', 'fa-coal-manager', 'inventory-pusat', 'operation-director', 'vice-president', 'president-director', 'procurement-pusat', 'administrator'],
  },
  {
    title: 'Purchase Order',
    route: 'apps-procurement-purchase-order-list',
    icon: 'ShoppingCartIcon',
    role: ['super-admin','purchasing-cabang', 'warehouse-cabang','manager-lab-cabang','laboratory-manager','regional-manager', 'division-head', 'fa-coal-manager', 'inventory-pusat', 'operation-director', 'vice-president', 'president-director', 'procurement-pusat', 'administrator'],
  },
  {
    title: 'Purchase Order Finance',
    route: 'apps-procurement-purchase-order-list-finance',
    icon: 'ShoppingCartIcon',
    role: ['finance','fa-coal-manager'],
  },
  {
    title: 'PO Summary',
    route: 'apps-procurement-purchase-order-list-summary',
    icon: 'ShoppingCartIcon',
    role: ['division-head','fa-coal-manager','operation-director','vice-president','president-director','super-admin'],
  },
  {
    title: 'Purchase Management',
    route: 'apps-procurement-purchase-management-list',
    icon: 'ArchiveIcon',
    role: ['super-admin','purchasing-cabang','warehouse-cabang','manager-lab-cabang','laboratory-manager','regional-manager', 'division-head', 'fa-coal-manager', 'inventory-pusat', 'operation-director', 'vice-president', 'president-director', 'procurement-pusat', 'administrator'],
  },
  {
    title: 'Payment Request',
    route: 'apps-procurement-payment-request-list',
    icon: 'DollarSignIcon',
    role: ['super-admin', 'administrator','finance', 'regional-manager', 'division-head', 'fa-coal-manager', 'operation-director',' vice-president', 'president-director', 'laboratory-manager'],
  },
  {
    title: 'Receive PO Purchasing',
    route: 'apps-purchase-order-pre-receive-purchasing',
    icon: 'ArrowDownCircleIcon',
    role: ['super-admin','purchasing-cabang', 'manager-lab-cabang', 'warehouse-cabang', 'laboratory-manager'],
  },
  {
    title: 'Receive PO Warehouse',
    route: 'apps-purchase-order-pre-receive-warehouse',
    icon: 'ArrowDownCircleIcon',
    role: ['super-admin','warehouse-cabang', 'laboratory-manager'],
  },
  {
    title: 'Transfers',
    route: 'apps-transfers-list',
    icon: 'CornerUpRightIcon',
    role: ['super-admin', 'warehouse-cabang', 'manager-lab-cabang', 'laboratory-manager', 'regional-manager','division-head', 'fa-coal-manager','finance'],
  },
  {
    title: 'Returns',
    route: 'apps-returns-list',
    icon: 'TruckIcon',
    role: ['super-admin', 'warehouse-cabang', 'manager-lab-cabang', 'laboratory-manager'],
  },
  {
    title: 'Stock Opname',
    route: 'apps-stock-opname-list',
    icon: 'BookOpenIcon',
    role: ['super-admin', 'finance', 'manager-lab-cabang', 'laboratory-manager', 'administrator'],
  },
  {
    title: 'Void',
    route: 'apps-void',
    icon: 'TrashIcon',
    role: ['super-admin', 'administrator'],
  },
  {
    title: 'Settings',
    route: 'apps-settings',
    icon: 'SettingsIcon',
    role: ['super-admin'],
  },
]
